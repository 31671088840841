<template>
	<div class="container">
		<div class="list-container">
			<div class="header-container">
				<div class="title">
					<div>{{$t('申请打包')}}</div>
					<div>
						(<span>{{1}}</span>/<span>{{2}}</span>)
					</div>
				</div>
				<div class="action">
					<div class="sub-btn" @click="applyPack">
						<span class="display-none">{{$t('去打包')}}</span>
						<div class="_pc-display-none">{{$t('下一步')}}</div>
					</div>
					<div @click="back" class="return-btn display-none">
							<svg xmlns="http://www.w3.org/2000/svg"  xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="17px">
								<path fill-rule="evenodd"  fill="rgb(66, 132, 220)" d="M9.522,4.267 L3.139,4.267 L5.276,2.137 C5.571,1.842 5.571,1.365 5.276,1.072 C4.980,0.777 4.502,0.777 4.208,1.072 L0.857,4.411 C0.695,4.573 0.627,4.789 0.641,4.999 C0.625,5.210 0.695,5.425 0.857,5.587 L4.206,8.926 C4.354,9.074 4.547,9.148 4.740,9.148 C4.933,9.148 5.126,9.074 5.274,8.926 C5.570,8.631 5.570,8.155 5.274,7.862 L3.180,5.774 L9.427,5.774 C12.065,5.774 14.289,7.846 14.348,10.476 C14.410,13.179 12.222,15.398 9.524,15.398 L8.558,15.398 C8.547,15.398 8.534,15.396 8.523,15.396 L2.041,15.396 C2.028,15.396 2.017,15.398 2.006,15.398 C1.645,15.414 1.353,15.680 1.296,16.027 L1.296,16.275 C1.353,16.621 1.645,16.887 2.003,16.905 L9.411,16.905 C12.884,16.905 15.794,14.164 15.858,10.703 C15.923,7.166 13.055,4.267 9.522,4.267 Z"/>
							</svg>
						<span>{{$t('返回')}}</span>
					</div>
				</div>
				<div class="mb-return _pc-display-none" @click="back">
						<svg style="transform: scale(0.85);" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="17px">
							<path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M9.522,4.267 L3.139,4.267 L5.276,2.137 C5.571,1.842 5.571,1.365 5.276,1.072 C4.980,0.777 4.502,0.777 4.208,1.072 L0.857,4.411 C0.695,4.573 0.627,4.789 0.641,4.999 C0.625,5.210 0.695,5.425 0.857,5.587 L4.206,8.926 C4.354,9.074 4.547,9.148 4.740,9.148 C4.933,9.148 5.126,9.074 5.274,8.926 C5.570,8.631 5.570,8.155 5.274,7.862 L3.180,5.774 L9.427,5.774 C12.065,5.774 14.289,7.846 14.348,10.476 C14.410,13.179 12.222,15.398 9.524,15.398 L8.558,15.398 C8.547,15.398 8.534,15.396 8.523,15.396 L2.041,15.396 C2.028,15.396 2.017,15.398 2.006,15.398 C1.645,15.414 1.353,15.680 1.296,16.027 L1.296,16.275 C1.353,16.621 1.645,16.887 2.003,16.905 L9.411,16.905 C12.884,16.905 15.794,14.164 15.858,10.703 C15.923,7.166 13.055,4.267 9.522,4.267 Z"/>
						</svg>
					<span>{{$t('返回')}}</span>
				</div>
			</div>
			<div class="main-container">
				<div class="header">
					<span>{{$t('已选择包裹')}}</span>
					<span>({{listSelect.length}})</span>
				</div>
				<CheckboxGroup class="list" v-model="listSelect" @on-change="CheckboxGroup">
					<Checkbox class="item" v-for="(item,index) in list" :label="item" :key="index">
					    <div class="content">
							<div class="img"></div>
							<div>
								<span>{{$t('快递单号')}}：</span>
								<span>{{item.expresssn}}</span>
							</div>
						</div>
					</Checkbox>
				</CheckboxGroup>
			</div>
		</div>
	</div>
</template>

<script>
	import { getUserReportList } from '@/api/jiyun.js'
	export default {
		data() {
			return {
				list:[],//包裹列表
				listSelect:[]//包裹列表
			}
		},
		created() {
			this.getList()
		},
		mounted() {},
		methods: {
			// 选择包裹
			CheckboxGroup:function(e) {
				this.listSelect = e
			},
			// 返回上一页
			back(){
				this.$router.back()
			},
			// 申请打包
			applyPack:function() {
				if(this.list.length<1) {
					return this.$Message.error(this.$t('暂无数据'))
				}
				if(this.listSelect.length<1) {
					return this.$Message.error(this.$t('请选择包裹'))
				}
				let choose_list = [];
				this.listSelect.forEach((item,index)=>{
					let data = {};
					data.expresssn = item.expresssn;
					data.storehouse_name = item.storehouse_name;
					data.id = item.id;
					choose_list.push(data)
				});
				this.$router.push({
					path: '/detail',
					query:{
						listSelect:JSON.stringify(choose_list)
					}
				})
			},
			// 包裹列表
			getList() {
				getUserReportList({
					// page: that.page,
					// limit: that.limit,
					status: 20,
					filter: 'can_package'
				}).then((res) => {
					this.list = res.data.list;
				})
			}
		}
	}
</script>

<style scoped>
	.container {
		color: #333333;
	}
	/* pc */
	@media screen and (min-width: 768px) {
		.container {
			width: 100%;
			padding: 2.5rem 17.5rem;
			background-color: #f5f5f5;
		}

		.list-container {
			background-color: #ffffff;
			width: 100%;
			border-radius: 0.625rem;
		}

		.header-container {
			padding: 2.1875rem 1.5625rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #E9E9E9;
		}
		.header-container .title {
			display: flex;
			align-items: center;
			column-gap: 0.375rem;
			font-size: 2rem;
			font-weight: bold;
			flex: 1;
		}
		.header-container .action {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 0.9375rem;
		}
		.header-container .action .sub-btn {
			font-size: 1.125rem;
			color: #fff;
			background: #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			cursor: pointer;
		}
		.header-container .action .return-btn {
			font-size: 1.125rem;
			color: #4284DC;
			border:1px solid #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.header-container .action .return-btn span{
			margin-left: 0.3125rem;
		}
		.main-container {
			width: 100%;
			padding: 1.5625rem 1.5625rem;
			font-size: 1.25rem;
		} 
		.main-container .header {
			font-size: 1.5rem;
			color: #4284DC;
			margin-bottom: 1.5625rem;
		}
		.main-container .list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1.5625rem;
			grid-row-gap: 1.125rem;
		}
		.main-container .list .item{
			width: 100%;
			padding: 1.25rem 0.9375rem;
			background: #f5f5f5;
			border-radius: 0.625rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row-reverse;
		}
		.main-container .list .item .content {
			display: flex;
			align-items: center;
			font-size: 1.25rem;
		}
		.main-container .list .item .content .img{
			width: 0.9375rem;
			aspect-ratio: 1/1;
			margin-right: 0.625rem;
			background: url('~@/assets/image/hangda/baoguo.png');
			background-position: center center;
			background-size: 100% 100%;
		}
		.main-container .list .item:hover{
			background-color: rgba(66, 132, 220, 0.1);
			color: #4284DC;
		}
		.main-container .list .item:hover .content .img{
			width: 0.9375rem;
			aspect-ratio: 1/1;
			margin-right: 0.625rem;
			background: url('~@/assets/image/hangda/baoguo1.png');
			background-position: center center;
			background-size: 100% 100%;
		}
		.main-container .list .item-select {
			background-color: rgba(66, 132, 220, 0.1);
			color: #4284DC;
		}
		.main-container .list .item:hover .content .img-select{
			width: 0.9375rem;
			aspect-ratio: 1/1;
			margin-right: 0.625rem;
			background: url('~@/assets/image/hangda/baoguo1.png');
			background-position: center center;
			background-size: 100% 100%;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1288px) {
		.container {
			width: 100%;
			padding: 2.5rem 3.5rem;
			background-color: #f5f5f5;
		}

		.list-container {
			background-color: #ffffff;
			width: 100%;
			border-radius: 0.625rem;
		}

		.header-container {
			padding: 2.1875rem 1.5625rem;
		}
		.main-container .list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1.5625rem;
			grid-row-gap: 1.125rem;
		}
	}

	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px) {
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		.container {
			width: 100%;
		}
		.list-container {
			background-color: #ffffff;
			width: 100%;
			border-radius: 0.625rem;
		}
		
		.header-container {
			padding: 0.75rem 0rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #E9E9E9;
			background-color: #4284DC;
			position: relative;
		}
		.header-container .title {
			color: #ffffff;
			display: flex;
			align-items: center;
			column-gap: 0.375rem;
			font-size: 1.25rem;
			justify-content: center;
			flex: 1;
		}
		.header-container .action {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 0.9375rem;
			
		}
		.header-container .mb-return {
			color: #fff;
			position: absolute;
			left: 0;
			padding-left: 0.9375rem;
			display: flex;
			align-items: center;
			column-gap: 0.25rem;
		}
		.header-container .mb-return svg {
			transform: scale(0.6);
		}
		.header-container .action .sub-btn {
			position: fixed;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			bottom: 0;
			left: 0;
			padding: 1.25rem;
			background: #fff;
			box-sizing: border-box;
			box-shadow: -1px 0px 5px rgba(0,0,0,0.2);
			cursor: pointer;
			z-index: 9;
		}
		.header-container .action .sub-btn div{
			width: 100%;
			background: #4284DC;
			color: #fff;
			border-radius: 0.375rem;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.625rem 0;
			font-size: 0.9375rem;
		}
		.header-container .action .return-btn {
			font-size: 1.125rem;
			color: #4284DC;
			border:1px solid #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.header-container .action .return-btn span{
			margin-left: 0.3125rem;
		}
		.main-container {
			width: 100%;
			padding: 1.5625rem 0.9375rem;
			font-size: 1.25rem;
		} 
		.main-container .header {
			font-size: 1.125rem;
			color: #4284DC;
			margin-bottom: 1.5625rem;
		}
		.main-container .list {
			display: grid;
			grid-template-columns: 1fr;
			grid-column-gap: 1.5625rem;
			grid-row-gap: 0.625rem;
		}
		.main-container .list .item{
			width: 100%;
			padding: 0.9375rem 0.9375rem;
			background: #f5f5f5;
			border-radius: 0.625rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row-reverse;
		}
		.main-container .list .item .content {
			display: flex;
			align-items: center;
			font-size: 0.9375rem;
		}
		.main-container .list .item .content .img{
			width: 0.875rem;
			aspect-ratio: 1/1;
			margin-right: 0.625rem;
			background: url('~@/assets/image/hangda/baoguo.png');
			background-position: center center;
			background-size: 100% 100%;
		}
		
		.main-container .list .item-select {
			background-color: rgba(66, 132, 220, 0.1);
			color: #4284DC;
		}

	}
</style>
<style>
/*屏幕在480px到768之间（主要是手机屏幕）*/
@media screen and (max-width: 768px) {
	/* .page-header-container {
		display: none !important;
	}
	.page-footer-container {
		display: none !important;
	} */
}
</style>